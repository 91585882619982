<template>
  <div>
    <h1>RxDB schemas migration</h1>
    <div v-if="rxdbIsEnabled">
      <br />
      <p>
        Copy the schemas in src/rxdb/schemas.js file.<br /><br />
        These schemas contains the migrationStrategies generated by comparing
        the current schemas.js file, and the new schemas generated from VuexORM
        models.<br />
        If you renamed a property, and so want to preserve the old value, patch
        manually these schemas, in order to copy the old property to the new
        property, in a migration strategy function.<br /><br />
        At next loading, the migrations strategies will be applied:<br />
        - Removed collections in the schemas will be deleted by looking the
        difference between the current RxDB database, and the collections
        contained in the schemas.<br />
        - Removed properties in the schemas will be deleted automatically by
        RxDB when injecting the new schemas in RxDB.<br />
        - Updated properties with new types will be migrated automatically by
        RxDB by applying the migration stratregies.<br /><br />
        Documentation link: https://rxdb.info/data-migration.html
      </p>
      <hr />
      <h2 v-if="update">The file src/rxdb/schemas.js needs update!</h2>
      <hr v-if="update" />
      <h2>
        Delta
        <VBtn dark depressed rounded icon @click="displayDelta = !displayDelta"
          ><v-icon>{{ displayDelta ? "mdi-eye-off" : "mdi-eye" }}</v-icon></VBtn
        >
      </h2>
      <br />
      <pre v-if="displayDelta">{{ prettyPrintJson(delta) }}</pre>
      <hr />
      <h2>
        Schemas
        <VBtn dark depressed rounded icon @click="copy(schemasModule)"
          ><v-icon>mdi-content-copy</v-icon></VBtn
        >
      </h2>
      <br />
      <pre>{{ schemasModule }}</pre>
    </div>
    <div v-else>
      <p>RxDB is not enabled (VUE_APP_RXDB_ENABLE !== "true").</p>
    </div>
  </div>
</template>

<script>
import {
  generateRxDBSchemaForAllModels,
  getSchemasDelta,
  getSchemasWithMigrationStrategies,
} from "@socotec.io/vuex-orm-rxdb-bridge";
import { schemas as currentSchemas } from "@/rxdb/schemas";
import { rxdbModels, rxdbFieldItems, rxdbFieldTypes } from "@/store";

export default {
  data() {
    return {
      displayDelta: false,
      delta: null,
      schemas: null,
    };
  },
  created() {
    if (!this.rxdbIsEnabled) {
      return;
    }
    const newSchemas = generateRxDBSchemaForAllModels(
      rxdbModels,
      rxdbFieldItems,
      rxdbFieldTypes
    );
    this.delta = getSchemasDelta(currentSchemas, newSchemas);
    this.schemas = getSchemasWithMigrationStrategies(
      currentSchemas,
      newSchemas
    );
  },
  methods: {
    copy(data) {
      navigator.clipboard.writeText(data);
    },
    prettyPrintJson(data) {
      if (data) {
        return JSON.stringify(data, null, 2);
      }
      return "";
    },
    getCollectionVersions(schema) {
      const versions = {};
      Object.keys(schema).forEach((key) => {
        versions[key] = schema[key].schema.version;
      });
      return versions;
    },
  },
  computed: {
    rxdbIsEnabled() {
      const rxdbEnable = process.env.VUE_APP_RXDB_ENABLE;
      return rxdbEnable === "true" || rxdbEnable === true;
    },
    schemasModule() {
      if (!this.rxdbIsEnabled) {
        return "";
      }
      let schemas = this.schemas.replaceAll(
        "function anonymous(oldDoc\n) {",
        "(oldDoc) => {"
      );
      schemas = schemas.replaceAll(
        "return oldDoc;\n}",
        "return oldDoc;\n      }"
      );
      return "const schemas = " + schemas + ";\n\nexport { schemas };";
    },
    update() {
      if (!this.rxdbIsEnabled) {
        return false;
      }
      if (this.schemas) {
        return (
          this.delta.old.length != 0 ||
          Object.keys(this.delta.new).length != 0 ||
          Object.keys(this.delta.modified).length != 0
        );
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
div {
  padding: 30px;
  color: white;
  background-color: black;
}

h1,
h2 {
  color: white;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
